<template>
  <v-container fluid class="fourth-section-bg-img my-16">
    <v-row no-gutters justify="center">
      <v-col
        cols="12"
        sm="8"
        class="fourth-section-text d-flex justify-center"
        align-self="center"
      >
        <v-card class="align-self-center" color="transparent" elevation="0">
          <h1 class="text-h5 text-sm-h4 text-md-h3 text-white text-center">
            EN D&#39;CAM CONTAMOS CON LOS MEJORES PROFESIONALES
          </h1>
          <div class="d-flex justify-center">
            <v-btn
              class="mt-5 text-uppercase text-primary bg-white"
              rounded="pill"
              size="x-large"
              to="/about"
            >
              CONOCE A NUESTRO EQUIPO
            </v-btn>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "HomeFourthSection",

  components: {},
  data: () => ({
    //
  }),
});
</script>

<style scoped>
.fourth-section-bg-img {
  background-image: url("../../assets/home-image-fourth-section.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left top;
}
.fourth-section-text {
  height: 200px;
  width: auto;
}
@media screen and (min-width: 601px) and (max-width: 960px) {
  .fourth-section-text {
    height: 300px;
  }
}
@media screen and (min-width: 961px) {
  .fourth-section-text {
    height: 400px;
  }
}
</style>
