<template>
  <v-footer class="bg-primary mt-16 py-16">
    <v-row justify="center" no-gutters>
      <v-btn to="/" color="white" variant="text">Inicio</v-btn>
      <v-btn to="/about" color="white" variant="text">Quienes Somos</v-btn>
      <v-btn to="/especialidades" color="white" variant="text"
        >Especialidades</v-btn
      >
      <v-btn to="/promociones" color="white" variant="text">Promociones</v-btn>
      <v-btn to="/noticias" color="white" variant="text">Noticias</v-btn>
      <v-btn to="/contacto" color="white" variant="text">Contacto</v-btn>
      <v-col class="text-center mt-5" cols="12">
        <v-btn
          href="https://www.facebook.com/Clinicadentaldcam/"
          variannt="outlined"
          color="white"
          class="mx-2"
          target="_blank"
        >
          <v-icon color="primary">mdi-facebook</v-icon>
        </v-btn>
        <v-btn
          href="https://www.instagram.com/clinicadentaldcam/"
          variannt="outlined"
          color="white"
          class="mx-2"
          target="_blank"
        >
          <v-icon color="primary">mdi-instagram</v-icon>
        </v-btn>
        <v-btn
          href="https://twitter.com/clinicadcam"
          variannt="outlined"
          color="white"
          class="mx-2"
          target="_blank"
        >
          <v-icon color="primary">mdi-twitter</v-icon>
        </v-btn>
      </v-col>
      <v-col class="text-center mt-16" cols="12">
        <span>Todos los Derechos Resrvados</span>
        {{ new Date().getFullYear() }} — <strong>D&#39;cam Dental</strong>
      </v-col>
    </v-row>
  </v-footer>
</template>
<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "AppFooter",

  components: {},

  data: () => ({
    links: [
      "Inicio",
      "Quienes Somos",
      "Especialidades",
      "Promociones",
      "Noticias",
      "Contacto",
    ],
  }),
});
</script>
<style></style>
