<template>
  <v-navigation-drawer location="left" temporary>
    <v-list nav>
      <v-list-item
        v-for="(item, i) in items"
        :key="i"
        :value="item"
        active-color="primary"
        :to="item.to"
      >
        <template v-slot:prepend>
          <v-icon color="primary" :icon="item.icon"></v-icon>
        </template>

        <v-list-item-title class="text-primary text-uppercase">
          {{ item.text }}
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  components: {},
  data: () => ({
    items: [
      { text: "inicio", icon: "mdi-chevron-right", to: "/" },
      { text: "quienes somos", icon: "mdi-chevron-right", to: "/about" },
      {
        text: "especialidades",
        icon: "mdi-chevron-right",
        to: "/especialidades",
      },
      { text: "promociones", icon: "mdi-chevron-right", to: "/promociones" },
      { text: "noticias", icon: "mdi-chevron-right", to: "/noticias" },
      { text: "contacto", icon: "mdi-chevron-right", to: "/contacto" },
    ],
  }),
};
</script>

<style></style>
