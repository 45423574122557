<template>
  <v-container class="my-16">
    <v-row>
      <v-col
        cols="10"
        sm="10"
        offset-sm="1"
        md="6"
        offset-md="0"
        class="order-first"
      >
        <v-img cover src="../../assets/home-image-second-section.png"></v-img>
      </v-col>
      <v-col
        cols="10"
        offset="1"
        sm="10"
        offset-sm="1"
        md="6"
        offset-md="0"
        align-self="center"
      >
        <p class="text-h5 text-sm-h4 text-md-h5 text-secondary mb-3">
          Para D&#39;Cam la salud dental es primordial para desenvolvernos y
          vivir una vida plena.
        </p>
        <p class="text-h5 text-sm-h4 text-md-h5 text-secondary mb-3">
          Nuestro compromiso es que todos se sientan bienvenidos y
          c&oacute;modos.
        </p>
        <p class="text-h5 text-sm-h4 text-md-h5 text-secondary mb-3">
          Hay tratamientos para todas las necesidades
        </p>
        <p class="text-h5 text-sm-h4 text-md-h5 text-secondary mb-3">
          &#161;Te esperamos&#33;
        </p>
        <v-btn
          class="mt-5 text-uppercase text-white bg-primary"
          rounded="pill"
          size="x-large"
          href="https://0919a6c811dcd8d87f423a7f84e3b184fcddd545.agenda.softwaredentalink.com/agendas/agendamiento"
          target="_blank"
        >
          Rerserva tu Hora
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "HomeSecondSection",

  components: {},
});
</script>

<style scoped></style>
