<template>
  <v-container class="my-16">
    <!-- <v-row class="my-16">
      <v-col
        cols="12"
        sm="10"
        offset-sm="1"
        md="6"
        offset-md="1"
        lg="5"
        offset-lg="1"
        xl="4"
        offset-xl="2"
        class="order-first"
      >
        <v-img cover src="../../assets/consulta-el-golf.png"></v-img>
      </v-col>
      <v-col cols="10" sm="8" md="4" class="ml-10">
        <h1
          class="text-h4 text-secondary mb-5 text-uppercase text-center text-sm-left text-md-left"
        >
          Las Condes
        </h1>
        <p
          class="text-h5 text-sm-h4 text-md-h5 text-secondary text-center text-sm-left text-md-left mb-1"
        >
          Augusto Legu&iacute;a Sur, 79
        </p>
        <p
          class="text-h5 text-sm-h4 text-md-h5 text-secondary text-center text-sm-left text-md-left mb-1"
        >
          Piso 7 Oficina 703
        </p>
        <p
          class="text-h5 text-sm-h4 text-md-h5 text-secondary text-center text-sm-left text-md-left mb-1"
        >
          (A pasos del metro el Golf)
        </p>
        <p
          class="text-h5 text-secondary text-center text-sm-left text-md-left mb-1"
        >
          <a href="tel:+56222457092" class="text-primary text-decoration-none"
            >+56 2 2245 7092</a
          >
        </p>
        <div class="d-flex justify-center justify-sm-start">
          <v-btn
            class="mt-4 text-uppercase text-white bg-primary"
            rounded="pill"
            size="x-large"
            href="https://0919a6c811dcd8d87f423a7f84e3b184fcddd545.agenda.softwaredentalink.com/agendas/agendamiento"
            target="_blank"
          >
            Rerserva tu Hora
          </v-btn>
        </div>
      </v-col>
    </v-row> -->
    <v-row class="my-16">
      <v-col
        cols="12"
        sm="10"
        offset-sm="1"
        md="6"
        offset-md="1"
        lg="5"
        offset-lg="1"
        xl="4"
        offset-xl="2"
        class="order-first"
      >
        <v-img cover src="../../assets/consulta-cerrillos.png"></v-img>
      </v-col>
      <v-col cols="10" sm="8" md="4" class="ml-10">
        <h1
          class="text-h4 text-secondary mb-5 text-uppercase text-center text-sm-left text-md-left"
        >
          Cerrillos
        </h1>
        <p
          class="text-h5 text-sm-h4 text-md-h5 text-secondary text-center text-sm-left text-md-left mb-1"
        >
          Pedro Aguirre Cerda, 6381
        </p>
        <p
          class="text-h5 text-sm-h4 text-md-h5 text-secondary text-center text-sm-left text-md-left mb-1"
        >
          (a pasos del metro Cerrillos linea 6)
        </p>
        <p
          class="text-h5 text-secondary text-center text-sm-left text-md-left mb-1"
        >
          <a href="tel:+56227924080" class="text-primary text-decoration-none"
            >+56 2 2792 4080</a
          >
        </p>
        <div class="d-flex justify-center justify-sm-start">
          <v-btn
            class="mt-4 text-uppercase text-white bg-primary"
            rounded="pill"
            size="x-large"
            href="https://0919a6c811dcd8d87f423a7f84e3b184fcddd545.agenda.softwaredentalink.com/agendas/agendamiento"
            target="_blank"
          >
            Rerserva tu Hora
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "HomeFifthSection",

  components: {},
});
</script>
<style></style>
