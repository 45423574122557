<template>
  <v-app>
    <app-bar />
    <v-main>
      <router-view />
    </v-main>
    <app-footer />
    <!-- <v-card elevation="0" color="transparent" href=""></v-card> -->
    <v-btn
      href="https://api.whatsapp.com/send?phone=56950144822"
      class="whatsapp-button"
      icon="mdi-whatsapp"
      color="green"
      size="x-large"
      target="_blank"
    ></v-btn>
  </v-app>
</template>

<script>
import AppBar from "./components/Header/AppBar.vue";
import AppFooter from "./components/Footer/AppFooter.vue";

export default {
  name: "App",
  components: {
    AppBar,
    AppFooter,
  },

  data: () => ({
    //
  }),
};
</script>

<style scoped>
.whatsapp-button {
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  z-index: 999;
  border: #ffffff solid 5px;
}
</style>
