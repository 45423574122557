<template>
  <v-app-bar fixed elevation="4" height="150">
    <v-container>
      <v-row no-gutters>
        <v-avatar class="ml-5" color="transparent" :size="logo.size">
          <v-img
            class="ml-auto"
            :height="logo.size"
            :src="logo.path"
            alt="Clinica DcamDental"
          ></v-img>
        </v-avatar>
        <v-spacer></v-spacer>
        <v-btn
          to="/"
          color="primary"
          class="mt-16"
          variant="text"
          v-if="mdAndUp"
        >
          inicio
        </v-btn>
        <v-btn
          to="/about"
          color="primary"
          class="mt-16"
          variant="text"
          v-if="mdAndUp"
        >
          Quienes Somos
        </v-btn>
        <v-btn
          to="/especialidades"
          color="primary"
          class="mt-16"
          variant="text"
          v-if="mdAndUp"
        >
          Especialidades
        </v-btn>
        <v-btn
          to="/promociones"
          color="primary"
          class="mt-16"
          variant="text"
          v-if="mdAndUp"
        >
          Promociones
        </v-btn>
        <v-btn
          to="/noticias"
          color="primary"
          class="mt-16"
          variant="text"
          v-if="mdAndUp"
        >
          Noticias
        </v-btn>
        <v-btn
          to="/contacto"
          color="primary"
          class="mt-16"
          variant="text"
          v-if="mdAndUp"
        >
          Contacto
        </v-btn>
        <v-app-bar-nav-icon
          color="primary"
          class="mt-16"
          v-if="smAndDown"
          @click="showDrawer"
        ></v-app-bar-nav-icon>
      </v-row>
    </v-container>
  </v-app-bar>
  <navigation-drawer v-model="drawer" />
</template>

<script>
import { ref } from "vue";
import { useDisplay } from "vuetify";
import { defineComponent } from "vue";

// Components
import NavigationDrawer from "./NavigationDrawer.vue";

export default defineComponent({
  name: "AppBar",
  components: {
    NavigationDrawer,
  },
  setup() {
    const { smAndDown, mdAndUp } = useDisplay();

    const logo = ref({
      size: "120",
      path: require("@/assets/dcamdental_logo.png"),
    });

    const logoSize = () => {
      if (smAndDown) {
        logo.value.size = "80";
      }
    };

    const drawer = ref(false);

    const showDrawer = () => {
      drawer.value = !drawer.value;
    };

    return { smAndDown, mdAndUp, logo, logoSize, drawer, showDrawer };
  },
});
</script>
