<template>
  <header-banner />
  <home-second-section />
  <home-third-section />
  <home-fourth-section />
  <home-fifth-section />
  <home-sixth-section />
</template>

<script>
import { defineComponent } from "vue";

// Components
import HeaderBanner from "@/components/Sections/HeaderBanner.vue";
import HomeSecondSection from "@/components/Sections/HomeSecondSection.vue";
import HomeThirdSection from "@/components/Sections/HomeThirdSection.vue";
import HomeFourthSection from "@/components/Sections/HomeFourthSection.vue";
import HomeFifthSection from "@/components/Sections/HomeFifthSection.vue";
import HomeSixthSection from "@/components/Sections/HomeSixthSection.vue";

export default defineComponent({
  name: "HomeView",

  components: {
    HeaderBanner,
    HomeSecondSection,
    HomeThirdSection,
    HomeFourthSection,
    HomeFifthSection,
    HomeSixthSection,
  },
});
</script>
