<template>
  <v-container class="my-16">
    <v-row>
      <v-col cols="12" class="mt-16">
        <h1 class="text-h4 text-primary text-center">
          TRATAMIENTOS QUE BRINDAMOS EN D&#39;CAM
        </h1>
      </v-col>
    </v-row>
    <v-row class="mx-5">
      <v-col
        cols="12"
        sm="10"
        offset-sm="1"
        md="10"
        offset-md="1"
        lg="10"
        offset-lg="1"
        class="d-flex justify-center"
      >
        <v-container>
          <v-row>
            <v-col cols="12" sm="6" md="3">
              <v-card
                elevation="0"
                color="transparent"
                :href="link"
                target="_blank"
              >
                <v-img src="../../assets/home-img-link-1.png"></v-img>
              </v-card>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <v-card
                elevation="0"
                color="transparent"
                :href="link"
                target="_blank"
              >
                <v-img src="../../assets/home-img-link-2.png"></v-img>
              </v-card>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <v-card
                elevation="0"
                color="transparent"
                :href="link"
                target="_blank"
              >
                <v-img src="../../assets/home-img-link-3.png"></v-img>
              </v-card>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <v-card
                elevation="0"
                color="transparent"
                :href="link"
                target="_blank"
              >
                <v-img src="../../assets/home-img-link-4.png"></v-img>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6" md="3">
              <v-card
                elevation="0"
                color="transparent"
                :href="link"
                target="_blank"
              >
                <v-img src="../../assets/home-img-link-5.png"></v-img>
              </v-card>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <v-card
                elevation="0"
                color="transparent"
                :href="link"
                target="_blank"
              >
                <v-img src="../../assets/home-img-link-6.png"></v-img>
              </v-card>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <v-card
                elevation="0"
                color="transparent"
                :href="link"
                target="_blank"
              >
                <v-img src="../../assets/home-img-link-7.png"></v-img>
              </v-card>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <v-card
                elevation="0"
                color="transparent"
                :href="link"
                target="_blank"
              >
                <v-img src="../../assets/home-img-link-8.png"></v-img>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "HomeThirdSection",

  components: {},
  data: () => ({
    link: "https://0919a6c811dcd8d87f423a7f84e3b184fcddd545.agenda.softwaredentalink.com/agendas/agendamiento",
  }),
});
</script>

<style scoped>
.card-hover:hover {
}
</style>
