<template>
  <v-container fluid class="header-banner-bg-img">
    <v-row no-gutters justify="center">
      <v-col
        cols="12"
        sm="8"
        class="header-banner-text d-flex justify-start"
        align-self="center"
      >
        <v-card class="align-self-center" color="transparent" elevation="0">
          <h1 class="text-h5 text-sm-h4 text-md-h3 text-white">
            EN CL&Iacute;NICA DENTAL D&#39;CAM NOS DEDICAMOS A TU SONRISA
          </h1>
          <v-btn
            class="mt-5 text-uppercase text-primary bg-white"
            rounded="pill"
            size="x-large"
            href="https://0919a6c811dcd8d87f423a7f84e3b184fcddd545.agenda.softwaredentalink.com/agendas/agendamiento"
            target="_blank"
          >
            Reserva tu Hora
          </v-btn>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "HeaderBanner",

  components: {},
});
</script>

<style scoped>
.header-banner-bg-img {
  background-image: url("../../assets/header-banner-bg-img.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left top;
}
.header-banner-text {
  height: 200px;
  width: auto;
}
@media screen and (min-width: 601px) and (max-width: 960px) {
  .header-banner-text {
    height: 300px;
  }
}
@media screen and (min-width: 961px) {
  .header-banner-text {
    height: 600px;
  }
}
</style>
